@import '@xq/ui-kit/lib/styles/breakpoints.scss';

.user-info {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  gap: 20px;
}

.buttons {
  margin-bottom: 84px;

  @media (min-width: $md) {
    margin-bottom: 40px;
  }
}
