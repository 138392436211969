@import '@xq/ui-kit/lib/styles/colors.scss';
@import '@xq/ui-kit/lib/styles/breakpoints.scss';
@import '@xq/ui-kit/lib/styles/variables.scss';

.home {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100dvh - 55px);
  padding: 40px 0;

  @media (min-width: $sm) {
    padding: 60px 50px;
  }
}

.learning-cards {
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex-wrap: wrap;

  @media (min-width: $sm) {
    flex-direction: row;
  }
}

.app-cards {
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex-wrap: wrap;

  @media (min-width: $sm) {
    flex-direction: row;
  }
}

.user-info-col {
  display: flex;
  align-items: center;
  order: 0;

  @media (min-width: $md) {
    order: 1;
  }
}

.app-cards-col {
  order: 1;

  @media (min-width: $md) {
    order: 0;
  }
}

.card {
  width: 100%;
  position: relative;
  padding: 20px;
  border-radius: 8px;
  transition: background-color $transition-duration $transition-timing-function;
  cursor: pointer;
  color: $gray-100;

  &:hover {
    background: rgba(245, 245, 245, 0.7);

    .card-icon {
      opacity: 1;
    }
  }

  @media (min-width: $md) {
    margin-top: 0;
  }
}

.card-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  opacity: 0;
  transition: $transition-duration $transition-timing-function;
}

.mt-version-card {
  @extend .card;
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 20px;
  align-items: flex-start;
  margin-bottom: 40px;

  @media (min-width: $md) {
    margin-bottom: 0;
  }

  &_title {
    margin-right: 20px;
    margin-bottom: 6px;
  }
}

.app-card {
  @extend .card;
  width: unset;
  background: rgba(255, 255, 255, 0.9);
  transition: $transition-duration $transition-timing-function;
}

.user-info-card {
  @extend .card;
  margin-bottom: 40px;

  @media (min-width: $md) {
    margin-bottom: 0;
  }
}

.news-cards {
  display: grid;
  gap: 40px;
  width: 100%;
  max-width: 100%;

  @media (min-width: $sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $md) {
    grid-template-columns: repeat(4, 1fr);
  }
}
