@import '@xq/ui-kit/lib/styles/colors.scss';
@import '@xq/ui-kit/lib/styles/zIndex.scss';
@import '@xq/ui-kit/lib/styles/variables.scss';
@import '@xq/ui-kit/lib/styles/breakpoints.scss';

.release-notes {
  transition: opacity 0.5s $transition-timing-function;
  opacity: 1;

  &--hidden {
    transition: opacity 0.5s $transition-timing-function;
    opacity: 0;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  @media (min-width: $md) {
    justify-content: end;
  }
}

.learning-info {
  display: grid;
  gap: 20px;
  margin-bottom: 60px;

  &-card {
    display: block;

    a {
      text-underline: none;
    }
  }
}

.logo-icon {
  position: absolute;
  left: -44px;
  top: 4px;
}

.model-tree-title {
  position: relative;
}
