@import '@xq/ui-kit/lib/styles/colors.scss';
@import '@xq/ui-kit/lib/styles/variables.scss';

.card {
  color: $gray-100;
  height: 100%;
}

a {
  text-decoration: none;
}

.text {
  position: relative;

  div {
    display: inline;
  }
}

.arrow {
  position: absolute;
  bottom: -4px;
  margin-left: 4px;
}
