@import '@xq/ui-kit/lib/styles/mixins.scss';
@import '@xq/ui-kit/lib/styles/breakpoints.scss';
@import '@xq/ui-kit/lib/styles/variables.scss';
@import '@xq/ui-kit/lib/styles/colors.scss';

.content {
  display: grid;
  grid-template-columns: 310px 1fr;
  gap: 40px;

  &-right {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.modal {
  width: 860px;
  max-width: 80vw;
  max-height: calc(100vh - 160px);
  background: $white-0;
  padding: 40px;
  border: 0;
  @include scrollbar();
  overflow-x: auto;
  overflow-y: auto;
  box-sizing: border-box;
  border-radius: 12px;
  box-shadow: 0 8px 8px 0 rgba(18, 18, 18, 0.1);

  &::backdrop {
    background: rgba(18, 18, 18, 0.7);
    animation: show $transition-duration-long $transition-timing-function normal;
  }

  &.hide {
    animation: hide $transition-duration-long $transition-timing-function normal;

    &::backdrop {
      animation: hide $transition-duration-long $transition-timing-function
        normal;
    }
  }
}

dialog[open] {
  animation: show $transition-duration-long $transition-timing-function normal;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
