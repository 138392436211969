@import '@xq/ui-kit/lib/styles/colors.scss';
@import '@xq/ui-kit/lib/styles/variables.scss';

.card {
  height: 107px;
  width: 172px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  overflow: hidden;
  color: $gray-100;
  transition: background $transition-duration $transition-timing-function;

  &:has(.image) {
    width: 280px;
  }

  &:hover {
    background: rgba(245, 245, 245, 0.7);
  }
}

a {
  text-decoration: none;
}

.text {
  width: 150px;
  align-items: center;
  justify-content: center;
  margin: 10px;
  position: relative;

  div {
    display: inline;
  }
}

.arrow {
  position: absolute;
  bottom: -4px;
  margin-left: 4px;
}

.image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
  background-repeat: no-repeat;
}

.image-container {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.text-block {
  display: flex;
  align-items: center;
  justify-content: center;
}
